import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import classes from './TextCycling.module.scss';

const TextCyclingComp = (props) => {
	const { textArray = [], className = '' } = props;
	const [index, setIndex] = useState(0);
	useEffect(() => {
		const intervalId = setInterval(() => {
			setIndex((prevIndex) => (prevIndex + 1) % textArray.length);
		}, 1500);

		return () => clearInterval(intervalId);
	}, [textArray.length]);

	return (
		<div className={clsx(classes['text-cycling'], className)}>
			<div className="animated-text tw-transition-transform tw-duration-500 tw-typography-title3">
				{textArray[index]}
			</div>
		</div>
	);
};

TextCyclingComp.propTypes = {
	className: PropTypes.string,
	textArray: PropTypes.array,
};

export const TextCycling = React.memo(TextCyclingComp);
