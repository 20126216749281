const routeURLs = {
	home: '/',
	createTrip: {
		default: '',
		results: '/results',
		confirmation: '/confirmation',
		tripConfirmation: '/trip-confirmation', //temporary route
		summary: '/summary',
		payment: '/payment-response',
		cancelBooking: '/cancel-booking',
		cancelSummary: '/cancel-summary',
		rescheduleBooking: '/reschedule-booking',
		rescheduleSummary: '/reschedule-summary',
		approvals: '/approvals',
	},
	dashboard: {
		default: '/dashboard',
		l2: '/dashboard/l2', // temporary adding here
	},
	teamEvents: {
		default: '',
	},
	carServices: {
		results: '/car-result',
		confirmation: '/car-confirmation',
		summary: '/car-summary',
	},
};

export default routeURLs;
