import * as React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { Popover } from '../../atoms/Popover';

const CapsuleTagComp = (props) => {
	const {
		customClass = '',
		icon = null,
		text = '',
		tooltip = false,
		...rest
	} = props;
	return (
		<div
			className={clsx(
				customClass,
				'tw-bg-color-surface-default-subtle tw-px-1 tw-rounded-2xl tw-border tw-border-solid tw-border-color-defaultWeak tw-flex tw-items-center',
				{
					'hover:tw-bg-color-surface-default-hover': tooltip,
				}
			)}
			{...rest}
		>
			<HOCWrapper {...props}>
				<>
					{icon && <span className=" tw-inline-block">{icon}</span>}
					<span className="tw-p-1 tw-typography-caption1Bold tw-text-color-text-subtle">
						{text}
					</span>
				</>
			</HOCWrapper>
		</div>
	);
};
const HOCWrapper = ({
	tooltip = false,
	tooltipContent,
	tooltipTrigger = 'hover',
	tooltipPlacement = 'top',
	children,
}) => {
	if (tooltip)
		return (
			<Popover
				placement={tooltipPlacement}
				trigger={tooltipTrigger}
				getPopupContainer={(trigger) => trigger.parentNode}
				content={tooltipContent}
				className="tw-flex tw-items-center tw-cursor-pointer"
			>
				{children}
			</Popover>
		);
	return children;
};

CapsuleTagComp.propTypes = {
	customClass: PropTypes.string,
	icon: PropTypes.element,
	tooltip: PropTypes.bool,
	tooltipPlacement: PropTypes.string,
	tooltipTrigger: PropTypes.oneOf([
		'hover',
		'focus',
		'click',
		'contextMenu',
		Array,
	]),
	tooltipContent: PropTypes.node,
};

export const CapsuleTag = React.memo(CapsuleTagComp);
