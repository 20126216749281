import { Suspense, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import { ampli } from 'analytics/ampli';
import RouteAllowed from 'auth/allowedAppforRoles';
import { appModules } from 'auth/appModuleConfig';
import { productNavVisibility } from 'auth/productNavVisibilityConfig';
import useNetworkInformation from 'hooks/useNetworkInformation';
import {
	useFetchNavbarPOSTMutation,
	useValidateTokenQuery,
} from 'redux/features/common/api/authService.api';
import {
	setClientInfo,
	setNavDetails,
} from 'redux/features/common/slice/authService.slice';
import GTMService from 'services/GTMService';
import LocalStorageService from 'services/LocalStorage';
import { errorTypeConstants } from 'utils/constants/errortypes';
import { formatting } from 'utils/formatHelper';
import { lazyComponent } from 'utils/LazyComponent';

import { ErrorBoundary } from 'components/compounds';
import { OfflineScreen } from 'components/compounds/SystemErrors';
import { AppLoader } from 'components/templates/Layout/AppLoader';

import URLs from '../utils/urls/routeUrls';

import AuthRoute from './AuthRoutes/Authentication';

const AppLayout = lazyComponent(() =>
	import('components/templates/Layout/AppLayout')
);
const TripSearchResults = lazyComponent(() =>
	import('views/CreateTrip/pages/Packaging')
);
const TripConfirmation = lazyComponent(() =>
	import('views/CreateTrip/pages/Confirmation')
);

const Summary = lazyComponent(() => import('views/CreateTrip/pages/Summary'));
const Booking = lazyComponent(() => import('views/CreateTrip/pages/Payment'));

const CancelBooking = lazyComponent(() =>
	import('views/CreateTrip/pages/CancelBooking')
);
const CancelSummary = lazyComponent(() =>
	import('views/CreateTrip/pages/CancelSummary')
);

const RescheduleBooking = lazyComponent(() =>
	import('views/CreateTrip/pages/RescheduleBooking')
);
const RescheduleSummary = lazyComponent(() =>
	import('views/CreateTrip/pages/RescheduleSummary')
);
const Approvals = lazyComponent(() =>
	import('views/CreateTrip/pages/Approvals')
);

const Dashboard = lazyComponent(() => import('views/Dashboard/Dashboard'));
const DashboardL2 = lazyComponent(() =>
	import('views/Dashboard/pages/L2/DashboardL2')
);

const CarServices = lazyComponent(() =>
	import('views/CarServices/pages/CarResults')
);
const CarConfiramtion = lazyComponent(() =>
	import('views/CarServices/pages/CarConfirmation')
);
const CarSummary = lazyComponent(() =>
	import('views/CarServices/pages/CarSummary')
);

const ErrorBoundaryLayout = () => (
	<ErrorBoundary errorType={500}>
		<Outlet />
	</ErrorBoundary>
);
const Page404 = lazyComponent(() => import('views/404'));

function AppRoutes() {
	const [appLoading, setAppLoading] = useState(true);
	const [isInitCheck, setInitCheck] = useState(false);
	const [showProductNav, setProductNav] = useState(false);
	const [loadAuthAPI, setLoadAuthAPI] = useState(false);
	const [isNetworkOffline, setNetworkOffline] = useState(false);
	const dispatch = useDispatch();
	const { loadTrackingScripts, loaderDetails, clientInfo } = useSelector(
		(state) => state.authService
	);
	const networkState = useNetworkInformation();
	const randomNumberRef = useRef();

	const getAppRoute = (route) => {
		if (productNavVisibility[route]) {
			setProductNav(productNavVisibility[route]);
		}
	};

	const {
		data: authAPIData,
		isSuccess: isAuthApiSuccess,
		isError: isAuthApiFailure,
	} = useValidateTokenQuery(undefined, {
		skip: !loadAuthAPI,
	}); // ! Step2 - Load Auth API

	const [callNavbar, { isSuccess: navbarSuccess, data: navData }] =
		useFetchNavbarPOSTMutation(); // ! Step3 - Navbar API only after AuthAPI

	//! Called after navbar details are fetched
	useEffect(() => {
		if (navbarSuccess) {
			setAppLoading(false);
			dispatch(setNavDetails(navData)); // ! Step4 - Once all APIs are loaded
		}
		return () => {};
	}, [dispatch, navData, navbarSuccess]);

	//! Called to validate the Role of user is valid and get nav details
	useEffect(() => {
		if (
			Object.keys(clientInfo).length !== 0 &&
			Object.getPrototypeOf(clientInfo) === Object.prototype
		) {
			const role = LocalStorageService.getRole();
			const data = clientInfo;
			setInitCheck(true);
			if (data?.applicable_groups.indexOf(role) === -1) {
				LocalStorageService.removeAllToken();
				window.location.href = process.env.REACT_APP_LOGIN_URL;
			}
			callNavbar({
				company_id: data && data?.company_ids?.usm_company_id,
				data: {
					refresh_token: LocalStorageService.getRefreshToken(),
				},
			});
			LocalStorageService.setLocale(data.locale);
		}
		return () => {};
	}, [callNavbar, clientInfo]);

	//! Called after Authorization API is called
	useEffect(() => {
		if (isAuthApiSuccess) {
			dispatch(setClientInfo(authAPIData));
		}
		if (isAuthApiFailure) {
			LocalStorageService.removeAllToken();
		}
	}, [authAPIData, dispatch, isAuthApiFailure, isAuthApiSuccess]);

	//! Called after getting loadTracking script is manually enabled
	useEffect(() => {
		if (loadTrackingScripts && clientInfo) {
			const data = clientInfo;
			const {
				first_name,
				last_name,
				company_ids,
				company_name,
				twak_url,
				user_email,
				user_ids,
				usm_role,
			} = data;

			if (twak_url) {
				let s1 = document.createElement('script'),
					s0 = document.getElementsByTagName('script')[0];
				s1.async = true;
				s1.src = twak_url;
				s1.setAttribute('crossorigin', '*');
				s0.parentNode.insertBefore(s1, s0);
			}
			GTMService.init({
				firstName: first_name,
				lastName: last_name,
				userName: user_email,
				email: user_email,
				clientId: company_ids?.travel_company_id,
				role: usm_role,
				userId: user_ids?.usm_user_id,
				companyName: company_name,
				usmCompanyId: company_ids?.usm_company_id,
			});

			ampli.load({
				environment: 'amplitudepoc',
				client: {
					apiKey: process.env.REACT_APP_AMPLITUDE_KEY,
					configuration: {
						minIdLength: 1,
					},
				},
			});
			ampli.identify(user_ids?.usm_user_id, {
				clientId: company_ids?.travel_company_id,
				role: usm_role,
				userId: user_ids?.usm_user_id,
				companyName: company_name,
				usmCompanyId: company_ids?.usm_company_id,
			});
		}
	}, [clientInfo, loadTrackingScripts]);

	useEffect(() => {
		if (networkState?.isOnline) {
			setNetworkOffline(false);
		} else {
			setNetworkOffline(true);
		}
	}, [networkState]);

	//! called to get random number for loader
	useEffect(() => {
		if (!randomNumberRef.current) {
			randomNumberRef.current = formatting.randomNumber(0, 5);
		}
	}, []);

	const router = createBrowserRouter([
		{
			element: <ErrorBoundaryLayout />,
			children: [
				{
					path: '/auth/:token/:role/:clientID/:approute',
					element: (
						<Suspense fallback={<AppLoader />}>
							<AuthRoute />
						</Suspense>
					),
				},
				{
					path: '/',
					element: (
						<Suspense fallback={<></>}>
							<AppLayout
								isloading={appLoading}
								showSecondaryMenu={showProductNav}
								isInitCheck={isInitCheck}
								setLoadAuthAPI={setLoadAuthAPI}
							/>
						</Suspense>
					),
					children: [
						{
							path: URLs.createTrip.results,
							element: (
								<RouteAllowed route={appModules.createTrip}>
									<TripSearchResults
										appLoaded={!appLoading}
										extraParam={{
											randomNumber:
												randomNumberRef.current,
										}}
									/>
								</RouteAllowed>
							),
						},
						{
							path: URLs.carServices.results,
							element: (
								<RouteAllowed route={appModules.carServices}>
									<CarServices appLoaded={!appLoading} />
								</RouteAllowed>
							),
						},
						{
							path: URLs.carServices.confirmation,
							element: (
								<RouteAllowed route={appModules.carServices}>
									<CarConfiramtion appLoaded={!appLoading} />
								</RouteAllowed>
							),
						},
						{
							path: URLs.carServices.summary,
							element: (
								<RouteAllowed route={appModules.carServices}>
									<CarSummary appLoaded={!appLoading} />
								</RouteAllowed>
							),
						},
						{
							path: URLs.createTrip.confirmation,
							element: (
								<RouteAllowed route={appModules.createTrip}>
									<TripConfirmation appLoaded={!appLoading} />
								</RouteAllowed>
							),
						},
						{
							path: URLs.createTrip.summary,
							element: (
								<RouteAllowed route={appModules.createTrip}>
									<Summary appLoaded={!appLoading} />
								</RouteAllowed>
							),
						},
						{
							path: URLs.createTrip.payment,
							element: (
								<RouteAllowed route={appModules.createTrip}>
									<Booking appLoaded={!appLoading} />
								</RouteAllowed>
							),
						},
						{
							path: URLs.createTrip.cancelBooking,
							element: (
								<RouteAllowed route={appModules.createTrip}>
									<CancelBooking appLoaded={!appLoading} />
								</RouteAllowed>
							),
						},
						{
							path: URLs.createTrip.cancelSummary,
							element: (
								<RouteAllowed route={appModules.createTrip}>
									<CancelSummary appLoaded={!appLoading} />
								</RouteAllowed>
							),
						},
						{
							path: URLs.createTrip.rescheduleBooking,
							element: (
								<RouteAllowed route={appModules.createTrip}>
									<RescheduleBooking
										appLoaded={!appLoading}
									/>
								</RouteAllowed>
							),
						},
						{
							path: URLs.createTrip.rescheduleSummary,
							element: (
								<RouteAllowed route={appModules.createTrip}>
									<RescheduleSummary
										appLoaded={!appLoading}
									/>
								</RouteAllowed>
							),
						},
						{
							path: URLs.dashboard.default,
							element: (
								<Dashboard
									isInitCheck={isInitCheck}
									setLoadAuthAPI={setLoadAuthAPI}
									appLoaded={!appLoading}
								/>
							),
						},
						{
							path: URLs.dashboard.l2,
							element: (
								<DashboardL2
									isInitCheck={isInitCheck}
									setLoadAuthAPI={setLoadAuthAPI}
									appLoaded={!appLoading}
								/>
							),
						},
					],
				},
				{
					path: URLs.createTrip.approvals,
					element: (
						<Approvals
							isInitCheck={isInitCheck}
							setLoadAuthAPI={setLoadAuthAPI}
							appLoaded={!appLoading}
						/>
					),
				},
			],
		},
		{
			path: '*',
			element: <Page404 />,
		},
	]);

	return (
		<>
			{/* //! Loader as a layer */}
			{loaderDetails.loading && (
				<div className=" tw-bg-white tw-fixed tw-h-screen tw-w-screen tw-z-[1000]">
					<AppLoader
						module={localStorage.getItem('app')}
						extraParam={{
							type: loaderDetails.type,
							randomNumber: randomNumberRef.current,
							navbarSpace: true,
						}}
					/>
				</div>
			)}
			{isNetworkOffline && (
				<div className=" tw-bg-white tw-fixed tw-h-screen tw-w-screen tw-z-[1001]">
					<OfflineScreen errorType={errorTypeConstants.OFFLINE} />
				</div>
			)}
			<RouterProvider router={router} />
		</>
	);
}

export default AppRoutes;
