import clsx from 'clsx';
import { Membership } from 'icons';

import classes from './CustomTag.module.scss';

export const CustomTag = ({ title = '', type = 'hilton_rate' }) => {
	let color = '#7855DF';
	switch (type) {
		case 'aaa_rate':
			color = '#0a65e7';
			break;
		default:
			break;
	}

	return (
		<div
			className={clsx(
				classes.root,
				'tw-flex tw-items-center tw-gap-1  tw-typography-caption2Bold tw-border-[1px]  tw-border-solid tw-rounded-tl-sm tw-rounded-tr-lg tw-rounded-bl-sm tw-rounded-br-lg tw-py-1 tw-px-2',
				{
					'tw-bg-purple-50 tw-text-color-purple-500 tw-border-color-purple-300':
						type === 'hilton_rate',
				},
				{
					'tw-bg-blue-50 tw-text-color-blue-500 tw-border-color-blue-300':
						type === 'aaa_rate',
				}
			)}
		>
			<Membership color={color} /> {title}
		</div>
	);
};
