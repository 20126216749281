import * as React from 'react';
import { forwardRef } from 'react';
import { Input as AntInput } from 'antd';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import classes from './Input.module.scss';

const InputComp = forwardRef((props, ref = null) => {
	const {
		className = '',
		defaultValue,
		children,
		value,
		onChange = () => {},
		placeholder = '',
		prefix = null,
		suffix = null,
		type = 'text',
		disabled = false,
		size = 'normal',
		variant = '',
		...rest
	} = props;

	console.log('varient', variant);
	return (
		<AntInput
			disabled={disabled}
			className={clsx(classes.input, classes[size], className)}
			onChange={onChange}
			placeholder={placeholder}
			value={value}
			prefix={prefix}
			suffix={suffix}
			type={type}
			defaultValue={defaultValue}
			ref={ref}
			varient={variant}
			{...rest}
		>
			{children}
		</AntInput>
	);
});

InputComp.propTypes = {
	type: PropTypes.string,
	className: PropTypes.string,
	placeholder: PropTypes.string,
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	value: PropTypes.any,
	defaultValue: PropTypes.any,
	prefix: PropTypes.element,
	suffix: PropTypes.element,
};

export const Input = React.memo(InputComp);
